import React, { createContext, useContext, useMemo, useState } from "react";
import jwtDecode from "jwt-decode";
import ProfileObject, { DealerShip } from "../datamodels/profile";
import { Constants } from "../Constants";

interface FavContextValue {
  code: string;
  rankOrg: string;
  profile: ProfileObject | undefined;
  name: string;
  salesCodes: string[] | [];
  dualDealer: string | undefined;
  setDualDealerFlag: () => void;
  dealerShipList: DealerShip[] | undefined;
  viewFeedback: boolean;
  getProfileData: () => any;
  setDealerCode: (code: string) => void;
  setMTDRankOrg: (rankOrg: string) => void;
  setName: (name: string) => void;
  setViewFeedBack: (viewFeedback: boolean) => void;
  pandacode: string;
  setPandACode: React.Dispatch<React.SetStateAction<string>>;
  joyRide:boolean|undefined;
  setJoyRide:(state:boolean)=> void;
  hyperlink: string;
  setHyperlink: React.Dispatch<React.SetStateAction<string>>;
}

const FavContext = createContext<FavContextValue | undefined>(undefined);

const FavContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [profile, setProfile] = useState<ProfileObject | undefined>(undefined);
  const [code, setCode] = useState<string>("F11010");
  const [rankOrg, setRankOrg] = useState<string>("NATIONAL");
  const [name, setName] = useState<string>("");
  const [salesCodes, setSalesCodes] = useState<string[]>([]);
  const [dealerShipList, setDealerShipList] = useState<
    DealerShip[] | undefined
  >();
  const [feedback, setFeedBack] = useState<boolean>(false);

  const [dualDealer, setDualDealer] = useState<string>();
  const [pandacode, setPandACode] = useState<string>("");
  const [joyRide,setJoyRide] = useState<boolean|undefined>(false);
  const [hyperlink, setHyperlink] = useState<string>(""); // State for hyperlink

  const getProfileData = async () => {
    const bearer_token = await window.sessionStorage.getItem(
      Constants.ACCESS_TOKEN
    );
    if (bearer_token) {
      const userData: ProfileObject = jwtDecode(bearer_token);
      const defaultSalesCode =
        JSON.parse(
          (window.sessionStorage.getItem("salesCodes") as string) ?? "{}"
        )[0] ?? "F11010";
      setDealerCode(defaultSalesCode);
      setSalesCodes(
        JSON.parse(window.sessionStorage.getItem("salesCodes") as string)
      );
      setMTDRankOrg(window.localStorage.getItem("rankOrg") ?? "NATIONAL");
      setProfile(userData);
      setDealerShipList(
        JSON.parse(window.sessionStorage.getItem("dealerShipList") as string)
      );
    }
    return profile;
  };

  const setDualDealerFlag = () => {
    const dualDealerFlag = JSON.parse(
      window.sessionStorage.getItem("dealerShipList") as string
    )[0].dualDealerFlag;
    setDualDealer(dualDealerFlag);
  };

  const setDealerCode = (newCode: string) => {
    if (profile?.fordUserType.toLowerCase().includes("employee")) {
      window.sessionStorage.setItem("salesCodes", JSON.stringify([newCode]));
      setSalesCodes([newCode]);
    }
    setCode(() => newCode);
  };

  const setMTDRankOrg = (newRankOrg: string) => {
    window.localStorage.setItem("rankOrg", newRankOrg);
    setRankOrg(() => newRankOrg);
  };

  const setViewFeedBack = (viewFeedback: boolean) => {
    setFeedBack(() => viewFeedback);
  };

  const contextValue = useMemo(
    () => ({
      profile,
      code,
      rankOrg,
      name,
      salesCodes,
      dealerShipList,
      joyRide,
      dualDealer,
      getProfileData,
      setDealerCode,
      setMTDRankOrg,
      setName,
      setSalesCodes,
      viewFeedback: feedback,
      setViewFeedBack: setFeedBack,
      setJoyRide,
      setDualDealerFlag,
      pandacode,
      setPandACode,
      hyperlink,
      setHyperlink,
    }),
    [
      profile,
      code,
      salesCodes,
      dealerShipList,
      dualDealer,
      getProfileData,
      setDealerCode,
      setSalesCodes,
      joyRide,
      setJoyRide,
      setDualDealerFlag,
      hyperlink,
    ]
  );
  return (
    <FavContext.Provider value={contextValue}>{children}</FavContext.Provider>
  );
};

const useFavContext = () => useContext(FavContext)!;

export { FavContextProvider, useFavContext };
