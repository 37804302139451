import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useFavContext } from "../context/FavContextProvider";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Feedback from "../components/feedback/Feedback";
import FlashScreen from "../pages/flashscreen/FlashScreen";
import ViewFeedBacks from "../components/viewfeedbacks/ViewFeedBacks";
import NotFound from "../components/notfound/NotFound";
import CarLoading from "../components/carloading/CarLoading";
import RouteHandler from "./RouteHandler";
import { Constants } from "../Constants";
import { OAuthRedirect } from "./OauthRedirect";
import ProfileObject from "../datamodels/profile";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { useGetFavourites } from "../apis/Favourites";

const Home = React.lazy(() => import("../pages/home/Home"));
const Sales = React.lazy(() => import("../pages/sales/Sales"));
const PartsService = React.lazy(
  () => import("../pages/partsservice/PartsService")
);

const ErrorComponent = React.lazy(
  () => import("../components/error/ErrorComponent")
);

const UnAuthorized = React.lazy(
  () => import("../components/unauthorizedpage/UnAuthorized")
);

const FinanceAndInsurance = React.lazy(
  () => import("../pages/financeandinsurance/FinanceAndInsurance")
);

const Subscriptions = React.lazy(
  () => import("../pages/subsrriptions/Subscriptions")
);

const TandC = React.lazy(() => import("../pages/TandC/TandC"));

const PartsServiceDualDealer = React.lazy(
  () => import("../pages/partsservice/PartsServiceDualDealer")
);

const getSessionStorageItem = (key: string) => {
  return key in window.sessionStorage
    ? window.sessionStorage.getItem(key)
    : null;
};

const AppRouter: React.FC = () => {
  const [app, setApp] = useState(<FlashScreen />);
  const {
    profile,
    setDealerCode,
    setViewFeedBack,
    viewFeedback,
    dualDealer,
    setDualDealerFlag,
    code,
      joyRide
  } = useFavContext();

  const routes = [
    { path: "/", element: app },
    { path: Constants.SALES_ROUTE, element: <Sales />, protected: true },
    { path: Constants.ERROR_ROUTE, element: <ErrorComponent /> },
    { path: Constants.UNAUTHORIZED_ROUTE, element: <UnAuthorized /> },
    { path: Constants.TERMS_AND_CONDITIONS, element: <TandC /> },
    {
      path: Constants.PARTS_SERVICE_ROUTE,
      element: <PartsService />,
      protected: true,
    },
    {
      path: Constants.FINANCE_INSURANCE_ROUTE,
      element: <FinanceAndInsurance />,
      protected: true,
    },
    {
      path: Constants.SUBSCRIPTIONS,
      element: <Subscriptions />,
      protected: true,
    },
    { path: Constants.FEEDBACK_ROUTE, element: <Feedback />, protected: true },
    {
      path: Constants.VIEW_FEEDBACK_ROUTE,
      element: <ViewFeedBacks />,
      adminOnly: true,
    },
    {
      path: "/oauth/redirect/*",
      element: <OAuthRedirect />,
    },
    { path: "*", element: <NotFound /> },
  ];

  const { getProfileData } = useFavContext();

  useEffect(() => {
    const path = window.location.href.split("/")[3];

    if (
      path === "error" ||
      path === "unauthorized" ||
      path === "termsAndConditions"
    )
      return;

    const access_token = getSessionStorageItem(Constants.ACCESS_TOKEN);

    if (access_token) {
      const userData: ProfileObject = jwtDecode(access_token);
      if (userData.fordUserType.toLocaleUpperCase() === Constants.DEALER) {
        axios
          .post(
            `${Constants.BACKEND_URL}/api/fordsitecode?salesCode=${
              userData?.fordSiteCode === "00009"
                ? "04060"
                : userData?.fordSiteCode === "00019" ? "10928" : userData?.fordSiteCode
            }`,
            {},
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((response) => {
            let salesCodes: string[] = [];
            const dealerShipList = response?.data?.dealerShipList?.map(
              ({
                dealerCode,
                dealerName,
                dualDealerFlag,
              }: {
                dealerCode: string;
                dealerName: string;
                dualDealerFlag: string;
              }) => {
                salesCodes.push(dealerCode);
                return { dealerCode, dealerName, dualDealerFlag };
              }
            );
            window.sessionStorage.setItem(
              "dealerShipList",
              JSON.stringify(dealerShipList ?? [])
            );

            window.sessionStorage.setItem(
              "salesCodes",
              JSON.stringify(salesCodes ?? [])
            );
            setDualDealerFlag();
            getProfileData();
            setApp(<Home />);
          })
          .catch(() => {
            window.sessionStorage.clear();
            setApp(<ErrorComponent />);
          });
      } else {
        getProfileData();
        setDealerCode(window.sessionStorage.getItem("salesCode") ?? "F11010");
        setApp(<Home />);
      }
      return;
    }

    if (!access_token) {
      const origin = window.location.origin;

      const redirectUri = `${origin}/oauth/redirect`;
      const queryParams = [
        `client_id=${Constants.WEB_CLIENT_ID}`,
        `resource=${Constants.WEB_RESOURCE}`,
        `response_type=token+id_token`,
        `redirect_uri=${redirectUri}`,
      ].join("&");

      const oauthIssuerUrl = `${Constants.AUTHORIZATION_URL}/adfs/oauth2/authorize`;

      window.location.href = `${oauthIssuerUrl}?${queryParams}`;
    } else {
      setApp(<FlashScreen />);
    }
  }, []);

  useEffect(() => {
    if (app === <FlashScreen />) {
      (
        document.getElementById("commonComponents") as HTMLElement
      ).style.display = "none";
    } else {
      (
        document.getElementById("commonComponents") as HTMLElement
      ).style.display = "block";
    }
  }, [app]);

  useEffect(() => {
    const clearCookies = () => {
      const cookies = document.cookie.split(";");

      for (const cookie of cookies) {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    };

    const handleBeforeUnload = () => clearCookies();

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useGetFavourites(profile?.CommonName || "");

  return (
    <BrowserRouter>
      <div className="min-h-screen flex flex-col justify-between overflow-visible bg-[#FAFAFA]">
        <div className={`${joyRide?"":"sticky"} top-0 z-50`} id="commonComponents">
          <Header />
          {viewFeedback && <Feedback />}
        </div>
        <Suspense fallback={<CarLoading />}>
          <div>
            <Routes>
              {routes.map(
                ({ path, element, protected: isProtected, adminOnly }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <Suspense fallback={<CarLoading />}>
                        <RouteHandler
                          isProtected={isProtected as boolean}
                          adminOnly={adminOnly as boolean}
                          element={element}
                        />
                      </Suspense>
                    }
                  />
                )
              )}
            </Routes>
          </div>
        </Suspense>
        <div id="footerComponent">
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default AppRouter;
