import React from "react";
import {Placement} from "react-joyride";

interface SalesType {
  [key: string]: string[];
}

const JOYRIDESTEPS_DESKTOP = [
  {
    target: 'body',
    content: <p className='font-FordF1Medium'>Welcome to the All-New Dealer Hub </p>,
    placement: "center" as Placement
  },
  {
    target: '#dealer-details-tile',
    content: <p className='font-FordF1Medium'>Here you can find information about your dealership and a quick view of your Sales Rankings!</p>,
  },
  {
    target: '#dealer-tile',
    content: <p className='font-FordF1Medium'>Click here to switch between Ford and Lincoln if you are a dual dealer.
    </p>
  },
  {
    target: '#Dashboard-tile',
    content: <p className='font-FordF1Medium'>Here’s a personalized dashboard, which you can customize to fit your needs. Once you log in, you'll find all your favorited links and metric tiles readily available.
    </p>
  },
  {
    target: '#Sales-tile',
    content: <p className='font-FordF1Medium'>On the Sales & Inventory tab you will find metrics relevant to sales rankings, unscheduled orders, inventory, relevant links and more. You can add any of these tiles to your dashboard by selecting the star in the upper right-hand corner of the tile.
    </p>
  },
  {
    target: '#Parts-tile',
    content: <p className='font-FordF1Medium'>On the Parts/Service & CX tab you will find metrics relevant to labor hours sold, battery/break/tire penetration, and links relevant to Parts/Service and CX. These tiles can also be favorited to show up on your dashboard.</p>
  },
  {
    target: '#Finance-tile',
    content: <p className='font-FordF1Medium'>Metrics related to Finance & Insurance coming in Q4! Visit this page for more information.</p>
  },
  {
    target: '#Subscriptions-tile',
    content: <p className='font-FordF1Medium'>Metrics related to Subscriptions coming in Q4! Visit this page for more information.</p>
  },
  {
    target: '#search-box',
    content: <p className='font-FordF1Medium'>In this section you can search for available tiles by typing in key works like “rank”, “SVA”, etc. to find metrics you need quickly.</p>
  },
  {
    target: '#message-tile',
    content: <p className='font-FordF1Medium'>Critical and time- sensitive messages will be posted here, ensuring you have immediate access to vital information for your business.</p>
  },
  {
    target: '#favorite-tile',
    content: <p className='font-FordF1Medium'>On each page, you will find helpful links related to your business. You can favorite any of those links to show up here in your dashboard for easy access.</p>
  },
  {
    target: '#tc-tile',
    content: <p className='font-FordF1Medium'>In the footer you can find Terms and Conditions</p>
  },
  {
    target: '#privacy-tile',
    content: <p className='font-FordF1Medium'>In the footer you can find Privacy Policy references</p>
  },
  {
    target: '#profile-tile',
    content: <p className='font-FordF1Medium'>To log out of the Dealer Hub, click here.</p>
  },
];

const JOYRIDESTEPS_MOBILE = [
  JOYRIDESTEPS_DESKTOP[0],
  JOYRIDESTEPS_DESKTOP[1],
  JOYRIDESTEPS_DESKTOP[2],
  JOYRIDESTEPS_DESKTOP[8],
  JOYRIDESTEPS_DESKTOP[9],
  JOYRIDESTEPS_DESKTOP[10],
  JOYRIDESTEPS_DESKTOP[11],
  JOYRIDESTEPS_DESKTOP[12],
]

const JOYRIDE_STYLES = {
  buttonNext:
      {
        width: '100px',
        height: '34px',
        borderRadius: '9999px',
        color: '#fff',
        fontFamily: 'FordF1-Bold',
        fontSize: '14px',
        backgroundColor: '#007bff',
        padding: '0',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
      },
  buttonBack:
      {
        color: '#00095b',
        fontFamily: 'FordF1-Bold',
        fontSize: '14px'
      },
  buttonSkip:
      {
        color: '#00095b',
        fontFamily: 'FordF1-Bold',
        fontSize: '14px'
      }
};

const JOYRIDELOCALE = {
  back: 'Back',
  close: 'Close',
  last: 'End',
  next: 'Next',
  skip: 'Skip'
};

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const SALES_TYPE: SalesType = {
  dailySales: [
    "Daily Ranking",
    "Daily",
    "Sales Ranking",
    "MTD",
    "National",
    "Market",
    "Region",
    "Zone",
    "State",
    "Ranking",
    "Sales",
  ],
  monthlySales: [
    "Monthly Sales",
    "Sales Ranking",
    "MTD",
    "YTD",
    "National",
    "Market",
    "Region",
    "Zone",
    "State",
    "PY ME Sales",
    "PY ME YTD Sales",
    "Ranking",
    "Sales",
    "+/- PY",
    "% PY",
  ],
  dealerObjectives: [
    "Objective",
    "Monthly Sales",
    "% to Goal",
    "Region % to Goal",
    "Nation % to Goal",
    "Dealer Objectives",
    "Region",
    "Nation",
  ],
  vehicleLineRanking: [
    "National",
    "Market",
    "Region",
    "Zone",
    "State",
    "Bronco",
    "Bronco Sport",
    "Eco online",
    "Eco Sport",
    "Edge",
    "Escape",
    "Expedition",
    "Explorer",
    "F150 Total",
    "F150 Lightning",
    "F150 Gas",
    "Super Duty Total",
    "Medium Truck",
    "Maverick",
    "Mustang",
    "MustangMachE",
    "Ranger",
    "Transit Connect Total",
    "E-Transit",
    "Transit Gas",
    "Transit Na",
    "VLR",
    "Vehicle Line Ranking",
  ],
  salesVelocityAnalysis: [
    "Sales Velocity Analysis",
    "Overall",
    "Dealership",
    "Current",
    "SVA",
    "National",
    "Regional",
    "Dealership",
  ],
  vehicleLine: [
    "Sales Velocity Analysis",
    "SVA",
    "Dealership",
    "Current",
    "Vehicle Line",
    "Bronco",
    "Bronco Sport",
    "Eco online",
    "Eco Sport",
    "Edge",
    "Escape",
    "Expedition",
    "Explorer",
    "F150 Total",
    "F150 Lightning",
    "F150 Gas",
    "Super Duty Total",
    "Medium Truck",
    "Maverick",
    "Mustang",
    "MustangMachE",
    "Ranger",
    "Transit Connect Total",
    "E-Transit",
    "Transit Gas",
    "Transit Na",
    "National",
    "Regional",
    "Dealership",
  ],
  dealerInventory: [
    "Dealer Inventory",
    "Model Year",
    "Vehicle Line",
    "Days in Stock",
    "Order Type",
    "Stock",
    "Bronco",
    "Bronco Sport",
    "Eco online",
    "Eco Sport",
    "Edge",
    "Escape",
    "Expedition",
    "Explorer",
    "F150 Total",
    "F150 Lightning",
    "F150 Gas",
    "Super Duty Total",
    "Medium Truck",
    "Maverick",
    "Mustang",
    "MustangMachE",
    "Ranger",
    "Transit Connect Total",
    "E-Transit",
    "Transit Gas",
    "Transit Na",
  ],
  fsaRecalls: [
    "All Status",
    "In System",
    "DealerStock",
    "InTransit",
    "Order Status",
    "Recall Types",
    "fsaRecalls",
  ],
  closedrecall: [
    "FSA Closed Recalls",
    "closed recall",
    "recall",
    "Last 1 day",
    "Last 3 days",
    "Last 7 days",
  ],
  gSalesVehicles: [
    "G-Sales Vehicles",
    "gsales",
    "Sales (Vehicles)",
    "Out-of-Service Units Sold to End User",
    "VIN",
  ],
  labourhoursold: ["Labour hours sold", "Regional Rank", "Labor Hours Sold"],
  bbt: [
    "Battery, Brakes and Tires",
    "Battery",
    "Brakes",
    "Tires",
    "Penetration",
    "Rank",
    "Coding",
    "Rank",
  ],
};

const Constants = {
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  WEB_RESOURCE: process.env.REACT_APP_WEB_RESOURCE,
  WEB_CLIENT_ID: process.env.REACT_APP_WEB_CLIENT_ID,
  AUTHORIZATION_URL: process.env.REACT_APP_AUTHORIZATION_URL,
  CHATBOT_URL: process.env.REACT_APP_CHATBOT_URL,
  DIALOGFLOW_CX_PROJECT_ID: process.env.REACT_APP_DIALOGFLOW_CX_PROJECT_ID,
  DIALOGFLOW_CX_AGENT_ID: process.env.REACT_APP_DIALOGFLOW_CX_AGENT_ID,
  OM_GSALES_URL: process.env.REACT_APP_OM_GSALES_URL,
  OM_TOPVOLUME_URL: process.env.REACT_APP_OM_TOPVOLUME_URL,
  ADFS_CODE: "code",
  BEARER_TOKEN: "bearer_token",
  ID_TOKEN: "id_token",
  CLIENT_CREDS: "client_creds",
  ACCESS_TOKEN: "access_token",

  HOME_ROUTE: "/",
  SALES_ROUTE: "/sales",
  ERROR_ROUTE: "/error",
  UNAUTHORIZED_ROUTE: "/unauthorized",
  PARTS_SERVICE_ROUTE: "/parts-service",
  FINANCE_INSURANCE_ROUTE: "/finance-insurance",
  CUSTOMER_EXPERIENCE_ROUTE: "/customer-experience",
  COMMERCIAL_ROUTE: "/commercial",
  OPERATIONS_ROUTE: "/operations",
  FEEDBACK_ROUTE: "/feedback",
  VIEW_FEEDBACK_ROUTE: "/view-feedback",
  SUBSCRIPTIONS: "/subscriptions",
  TERMS_AND_CONDITIONS: "termsAndConditions",
  DEALER: "DEALER",
  TOPVOLUME_TOP_RANK_TITLE: "Top 100 Volume Rank",
  TOPVOLUME_REGION_RANK_TITLE: "Regional Wild Card Rank",
  TOPVOLUME_GROUP_RANK_TITLE: "Dealer Group Ranking",
  TOPVOLUME_CYTD_TITLE: "CYTD Sales Count",
  VEHICLE_LINE_FORD: [
    { Bronco: "Bronco" },
    { "Bronco Sport": "Bronco Sport" },
    { "E-Series": "Econoline" },
    { EcoSport: "EcoSport" },
    { Edge: "Edge" },
    { Escape: "Escape" },
    { "Exped Total": "Expedition" },
    { Explorer: "Explorer" },
    { F150: "F150 Total" },
    { "F150 EV": "F150 Lightning" },
    { "F150 Gas": "F150 Gas" },
    { "F250-F600": "Super Duty Total" },
    { "F650-F750": "Medium Truck" },
    { Maverick: "Maverick" },
    { Mustang: "Mustang" },
    { MustangMachE: "MustangMachE" },
    { Ranger: "Ranger" },
    { "Trans Conn": "Transit Connect Total" },
    { "TransVan EV": "E-Transit" },
    { "Transit Gas": "Transit Gas" },
    { "Transit NA": "Transit NA" },
  ],
  VEHICLE_LINE_LINCOLN: [
    { Aviator: "Aviator Total" },
    { Corsair: "Corsair Total" },
    { Nautilus: "Nautilus" },
    { "Navig Tot": "Navigator Total" },
  ],
  VEHICLE_LINE_RANKING_FORD: [
    { Bronco: "Bronco" },
    { "Bronco Sport": "Bronco Sport" },
    { "E-Series": "Econoline" },
    { EcoSport: "EcoSport" },
    { Edge: "Edge" },
    { Escape: "Escape" },
    { "Exped Total": "Expedition" },
    { Explorer: "Explorer" },
    { F150: "F150 Total" },
    { "F150 EV": "F150 Lightning" },
    { "F150 Gas": "F150 Gas" },
    { "F250-F600": "Super Duty Total" },
    { "F650-F750": "Medium Truck" },
    { Maverick: "Maverick" },
    { Mustang: "Mustang" },
    { MustangMachE: "MustangMachE" },
    { Ranger: "Ranger" },
    { "Trans Conn": "Transit Connect Total" },
    { "TransVan EV": "E-Transit" },
    { "Transit Gas": "Transit Gas" },
    { "Transit NA": "Transit NA" },
  ],
  VEHICLE_LINE_RANKING_LINCOLN: [
    { Aviator: "Aviator" },
    { Corsair: "Corsair" },
    { Nautilus: "Nautilus" },
    { "Navig Tot": "Navigator" },
  ],
  VEHICLE_TYPE: [
    { RETAILANDFLEET: "Fleet&Retail" },
    { RETAIL: "Retail" },
    { FLEET: "Fleet" },
  ],
  RANKING_LEVELS_FORD: [
    { NATIONAL: "National" },
    { MARKETAREA: "Market" },
    { REGION: "Region" },
    { ZONE: "Zone" },
    { STATE: "State" },
  ],
  RANKING_LEVELS_LINCOLN: [
    { NATIONAL: "NATIONAL" },
    { MARKETAREA: "MARKET" },
    { ZONE: "ZONE" },
    { STATE: "STATE" },
  ],
  DOWNLOADITEMS: ["EXCEL", "PDF"],
  DIALOG_FLOW_JSScript:
    "https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js",
  MONTHS,
  JOYRIDELOCALE,
  JOYRIDESTEPS_DESKTOP,
  JOYRIDESTEPS_MOBILE,
  JOYRIDE_STYLES,
  PARTS_SERVICE_CARDS: ["BatteryBrakesTires", "LabourHoursSold"]
};

export { Constants };
