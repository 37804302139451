import { useState, useCallback, useEffect } from "react";
import { useFavContext } from "../../context/FavContextProvider";
import { CreateOption } from "../../utils/CreateOption";
import { useDealerSearch } from "../../apis/DealerSearch";

const AdminView: React.FC<{ setCanOpenDealerCodeMenu: any }> = ({
  setCanOpenDealerCodeMenu,
}) => {
  const { setDealerCode, setName, profile, setPandACode } = useFavContext();
  const [tempDealerCode, setTempDealerCode] = useState<string>("");
  const [optionList, setOptionList] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedBrand, setSelectedBrand] = useState<string>("");
  const { DealerData } = useDealerSearch(
    tempDealerCode,
    selectedBrand,
    profile
  );
  const validateAndSetDealerCode = useCallback(() => {
    if (optionList.some((dealer) => dealer.value === tempDealerCode)) {
      if (tempDealerCode.length === 5) {
        setDealerCode(`${selectedBrand}${tempDealerCode}`);
        setName(
          optionList
            .find((dealer) => dealer.value === tempDealerCode)
            ?.label.split("-")[1] ?? ""
        );
        setPandACode(DealerData[0]?.ebizPAndA);
        setCanOpenDealerCodeMenu(false);
        setTempDealerCode("");
      }
    } else {
      alert("Please Enter Valid Dealercode");
    }
  }, [tempDealerCode, setDealerCode, optionList]);

  useEffect(() => {
    setOptionList(() => CreateOption(DealerData));
  }, [DealerData]);

  useEffect(() => {
    setTempDealerCode("");
  }, [selectedBrand]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTempDealerCode(e.target.value);
    },
    []
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        validateAndSetDealerCode();
      }
    },
    [validateAndSetDealerCode]
  );

  const handleButtonClick = useCallback(() => {
    validateAndSetDealerCode();
  }, [validateAndSetDealerCode]);

  const handleBrandSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedBrand(e?.target?.value?.toUpperCase());
    },
    []
  );
  return (
    <>
      <div className="text-center mx-auto">
        <label htmlFor="dealershipBrand">
          <select
            className="border border-[#f1f1f1] w-[15rem] rounded p-1 px-1 my-1"
            onChange={handleBrandSelect}
            value={selectedBrand}
            data-testid="dealershipBrand"
          >
            <option value="">Please select brand</option>
            <option value="F">Ford</option>
            <option value="L">Lincoln</option>
          </select>
        </label>
      </div>
      <div className="text-center mx-auto">
        <label htmlFor="dealercode">
          <input
            placeholder="Please Enter Dealercode"
            type="text"
            id="dealercode"
            list="dealer-options"
            className="border border-[#f1f1f1] w-[15rem] rounded p-1 px-2 my-1"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            value={tempDealerCode}
            disabled={!selectedBrand}
            required
            autoComplete="off"
          />
          {tempDealerCode.length !== 5 ? (
            <datalist id="dealer-options">
              {optionList.map((dealer) => (
                <option key={dealer.label} value={dealer.value}>
                  {dealer.label}
                </option>
              ))}
            </datalist>
          ) : (
            ""
          )}
        </label>
        <button
          className="p-1 px-1 m-2 text-[#ffff] bg-fordBlue rounded block mx-auto"
          type="button"
          onClick={handleButtonClick}
          disabled={!selectedBrand || !tempDealerCode}
        >
          Proceed
        </button>
      </div>
    </>
  );
};
export default AdminView;
