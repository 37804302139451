import { DealerShip } from "../datamodels/profile";
import { Component } from "../datamodels/FavouriteModel";

export const getWidth = (userType: string) => {
  switch (userType) {
    case "lincoln":
      return 145;
    case "ford":
    default:
      return 95;
  }
};

export const getName = (
  dealerShip: DealerShip,
  name: string,
  isShowDealerCode: boolean
) => {
  const { dealerCode, dealerName } = dealerShip || "-";
  if (dealerShip) {
    return isShowDealerCode ? `${dealerCode} - ${dealerName}` : `${dealerName}`;
  } else if (name) {
    return name;
  } else {
    return "Test Dealership";
  }
};

export const getLincolnDualDealerFavourite = (
  favouriteData: Component[],
  partsServicesfavourite: string[],
  profile: any
) => {
  const filteredData = favouriteData?.filter(
    (item) => !partsServicesfavourite.includes(item?.cardName)
  );
  const messageCardObject = {
    addedAt: Date.now(),
    cardName: "LincolnPartsServiceMessage",
    favoriteId: 0,
    position: (filteredData?.length ?? 0) + 1,
    userId: profile?.CommonName,
  };
  favouriteData = [...(filteredData ?? []), messageCardObject];
  return favouriteData;
};

export const isPartsServiceCardAvailable = (
  favouriteData: Component[],
  partsServicesfavourite: string[]
) => {
  const hasPartsServiceFavouriteCard =
    favouriteData?.some((item) =>
      partsServicesfavourite.includes(item.cardName)
    ) ?? false;
  return hasPartsServiceFavouriteCard;
};

export const arrangePositions = ({ favList }: { favList: Component[] }) => {
  const arrangedList = favList.map((item, index) => ({
    ...item,
    position: index + 1,
  }));
  return arrangedList;
};
