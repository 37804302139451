import React from "react";
import { useFavContext } from "../../context/FavContextProvider";
import { Constants } from "../../Constants";
import {useLocation} from "react-router-dom";

const Footer: React.FC = () => {
  const { profile,joyRide,setJoyRide } = useFavContext();
  const location = useLocation();

  const renderFooter = () => {
    if (profile) {
      return (
          <div className="bg-[#00095B] p-4 w-full flex items-center text-white">
            <div className="flex-1 flex justify-center items-center text-[12px] font-FordF1Regular">
              <p className="mx-1">©2024 Ford Motor Company</p>
              <div className="mx-1 underline inline-block" id='tc-tile'>
                <a href={Constants.TERMS_AND_CONDITIONS} target="_blank">
                  Terms and Conditions
                </a>
              </div>
              <span className="inline-block mx-1">|</span>
              <div className="mx-1 underline inline-block" id='privacy-tile'>
                <a
                    href="https://www.ford.com/help/privacy/globalb2b/"
                    target="_blank"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            {location.pathname === "/" && (
            <div className="text-[12px] font-FordF1Medium">
              <button onClick={() => setJoyRide(true)} disabled={joyRide}>
                Start Tutorial
              </button>
            </div>
            )}
          </div>
      );
    }
    return <></>;
  };
  return <footer>{renderFooter()}</footer>;
};
export default Footer;
