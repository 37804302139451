import React from "react";

const Logout: React.FC<{ setLogoutMenu: any }> = ({ setLogoutMenu }) => {
  const handleLogout = () => {
    window.sessionStorage.clear();
    window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
  };

  return (
    <div
      className="border px-6  py-3 rounded text-black text-left bg-white z-50"
      data-testid="logout-component"
    >
      <div className="flex justify-between my-1">
        <div className="font-FordF1Bold text-[#333333]">Account</div>
        <div
          onClick={() => setLogoutMenu(false)}
          className="cursor-pointer"
          data-testid="close-menu"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.68 24L36 34.32L34.32 36L24 25.68L13.68 36L12 34.32L22.32 24L12 13.68L13.68 12L24 22.32L34.32 12L36 13.68L25.68 24Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <button
        onClick={handleLogout}
        className="w-[111px] h-[40px] bg-[#066FEF] font-FordF1Bold rounded-full my-1 text-white text-[14px]"
        data-testid="logout-button"
      >
        Sign Out
      </button>
    </div>
  );
};

export default Logout;
