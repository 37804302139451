import { useQuery } from "@tanstack/react-query";
import ProfileObject from "../datamodels/profile";
import { GetBearerToken } from "../utils/GetBearerToken";
import api from "../store/api";

const baseURL = "/api/v2/dealer/dealerSearch";
export const useDealerSearch = (
  code: string,
  brand: string,
  profile: ProfileObject | undefined
) => {
  const {
    data: DealerData,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["DealerSearch", code, brand],
    queryFn: async () => {
      const bearer_token = await GetBearerToken();
      const response = await api.post(
        baseURL,
        {
          url: `${
            process.env.REACT_APP_OM_DEALER_SEARCH_URL
          }/ctp/common/SearchPrimaryDealers/${
            brand === "F" ? "01" : "03"
          }?search=${code}`,
          method: "GET",
          expiryTimeInMinutes: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${bearer_token}`,
          },
        }
      );
      return response.data?.data;
    },
    enabled: code.length > 2 && !!profile?.uid && !!brand,
    staleTime: 1000 * 60 * 60 * 4,
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  });
  return { DealerData, loading, error };
};
