import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { IFeedBackModel } from "../../datamodels/FeedBackModel";
import { useFeedBackData } from "../../apis/Feedback";
import { useFavContext } from "../../context/FavContextProvider";

const ViewFeedBacks: React.FC = () => {
  const { profile } = useFavContext();
  const { loading, FeedBackData, error } = useFeedBackData(profile);
  const renderFeedbackData = () => {
    if (loading) {
      return <ProgressSpinner data-testid="progress-spinner" />;
    }
    if (error) {
      return (
        <div>
          <div>Something went wrong</div>
          <div>
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
            >
              Retry
            </button>
          </div>
        </div>
      );
    }
    if (FeedBackData) {
      if (FeedBackData.length === 0) {
        return (
          <div className="text-center text-2xl font-bold text-gray-500">
            No Feedbacks Available Yet
          </div>
        );
      }
      return (
        <div className="flex justify-around flex-wrap">
          {FeedBackData?.map((feedback: IFeedBackModel) => {
            return (
              <div
                key={feedback?.userId}
                className="bg-gray-100 p-4 m-4 rounded-lg shadow-md text-left"
              >
                <div className="font-bold text-gray-500 text-center my-2 font-FordF1Regular">
                  <span>Feedback By:</span>&nbsp;&nbsp;
                  <span>{feedback?.userId}</span>
                </div>
                <div className="font-bold text-gray-500 my-1 font-FordF1Regular">
                  <span>What did you like the most in the experience:</span>
                  &nbsp;&nbsp;
                  <span className="text-fordBlue">{feedback?.likes}</span>
                </div>
                <div className="font-bold text-gray-500 my-1 font-FordF1Regular">
                  <span>What would you like to see in the experience:</span>
                  &nbsp;&nbsp;
                  <span className="text-fordBlue">
                    {feedback?.recommendations}
                  </span>
                </div>
                <div className="font-bold text-gray-500 my-1 font-FordF1Regular">
                  <span>
                    How likely are you to recommend DealerHub to your collegue:
                  </span>
                  &nbsp;&nbsp;
                  <span className="text-fordBlue">{feedback?.rating}</span>
                </div>
                {feedback.mobile && (
                  <div className="font-bold text-gray-500 my-1 font-FordF1Regular">
                    <span>Phone Number:</span>&nbsp;&nbsp;
                    <span className="text-fordBlue">{feedback?.mobile}</span>
                  </div>
                )}
                {feedback.email && (
                  <div className="font-bold text-gray-500 my-1 font-FordF1Regular">
                    <span>Email:</span>&nbsp;&nbsp;
                    <span className="text-fordBlue">{feedback?.email}</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return <div>{renderFeedbackData()}</div>;
};

export default ViewFeedBacks;
