import { useQuery, useMutation } from "@tanstack/react-query";
import { Component } from "../datamodels/FavouriteModel";
import { GetBearerToken } from "../utils/GetBearerToken";
import api from "../store/api";
const baseURL = "api/v1/dealer/favourites";

export const useGetFavourites = (code: string) => {
  const {
    data: favouriteData,
    isLoading,
    error,
    refetch,
  } = useQuery<Component[], Error>({
    queryKey: ["favourites", code],
    queryFn: async () => {
      try {
        const bearer_token = await GetBearerToken();
        const response = await api.get(`${baseURL}?userId=${code}`, {
          headers: { Authorization: `Bearer ${bearer_token}` },
        });
        return response?.data?.data?.dealerFavourites;
      } catch (error: any) {
        if (error.response.status === 401) {
          throw new Error(error.response.message);
        }
        throw new Error(error.response.message);
      }
    },
    enabled: !!code,
    staleTime: 1000 * 60 * 60 * 4,
    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  });

  return { favouriteData, isLoading, error, refetch };
};

export const useAddFavourites = (refetchFavourites: () => void) => {
  const {
    mutateAsync: addFavourite,
    error,
    isPending: addPending,
  } = useMutation<
    Component[],
    Error,
    { userId: string | undefined; cardName: string }
  >({
    mutationFn: async ({ userId, cardName }) => {
      try {
        const bearer_token = await GetBearerToken();
        const response = await api.post(
          `${baseURL}/add?userId=${userId}`,
          { cardName },
          {
            headers: { Authorization: `Bearer ${bearer_token}` },
          }
        );
        return response?.data?.data?.dealerFavourites;
      } catch (error: any) {
        throw new Error(error.response.message);
      }
    },
    onSuccess: () => {
      refetchFavourites();
    },
  });

  return { addFavourite, error, addPending };
};

// Delete Favourite
export const useDeleteFavourite = (refetchFavourites: () => void) => {
  // Accept refetch function as a parameter
  const {
    mutateAsync: deleteFavourite,
    error,
    isPending: deletePending,
  } = useMutation<Component[], Error, { favoriteId: number; userId: string }>({
    mutationFn: async ({ userId, favoriteId }) => {
      try {
        const bearer_token = await GetBearerToken();
        const response = await api.delete(
          `${baseURL}/remove?userId=${userId}&favoriteId=${favoriteId}`,
          {
            headers: { Authorization: `Bearer ${bearer_token}` },
          }
        );
        return response?.data?.data?.dealerFavourites;
      } catch (error: any) {
        throw new Error(error.response.message);
      }
    },
    onSuccess: () => {
      refetchFavourites();
    },
  });

  return { deleteFavourite, error, deletePending };
};

export const useReArrangeFavourites = (refetchFavourites: () => void) => {
  const {
    mutateAsync: reArrangeFavourites,
    error,
    isPending: rearrangePending,
  } = useMutation<
    Component[],
    Error,
    { userId: string; reOrderedList: Component[] }
  >({
    mutationFn: async ({ userId, reOrderedList }) => {
      try {
        const bearer_token = await GetBearerToken();
        const response = await api.put(
          `${baseURL}/rearrange?userId=${userId}`,
          { dealerFavourites: reOrderedList },
          {
            headers: { Authorization: `Bearer ${bearer_token}` },
          }
        );
        return response?.data?.data?.dealerFavourites;
      } catch (error: any) {
        throw new Error(error.response.message);
      }
    },
    onSuccess: () => {
      refetchFavourites();
    },
  });

  return { reArrangeFavourites, error, rearrangePending };
};
